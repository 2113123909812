import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule, Mutation } from "vuex-module-decorators";
import ApiService from "@/core/services/ApiService";

@Module
export default class UPSMethodsModule extends VuexModule {
  /*accesToken = {} as AccessToken;

  get upsAuthResponse() {
    return this.accesToken;
  }

  @Mutation
  [Mutations.SET_UPS_SHIPMENT](payload) {
    this.accesToken = payload;
  }*/

  @Action
  [Actions.GET_UPS_SHIPMENT]() {
    ApiService.setHeader();
    ApiService.get("UpsCatalog/GetShipment")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_UPS_SHIPMENT, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.GET_UPS_SHIPPING_RATES]() {
    ApiService.setHeader();
    ApiService.get("UpsCatalog/GetShippingRates")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_UPS_SHIPPING_RATES, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
  @Action
  [Actions.GET_UPS_VERIFY_ADDRESS]() {
    ApiService.setHeader();
    ApiService.get("UpsCatalog/GetVerifyAddress")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_UPS_VERIFY_ADDRESS, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
}
