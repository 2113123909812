import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import {
  ApplicationUser,
  NewApplicationUser,
} from "../../core/types/UserTypes";
import ApiService from "@/core/services/ApiService";
import store from "..";

@Module
export default class UserModule extends VuexModule {
  applicationUsers: Array<ApplicationUser> = [];
  filterApplicationUsers: Array<ApplicationUser> = [];
  newApplicationUser = {} as NewApplicationUser;
  applicationUser = {} as ApplicationUser;
  selectedApplicationUserProfile = {} as ApplicationUser;

  /**
   * Get current application users object
   * @returns ApplicationUsers
   */
  get applicationUsersList(): ApplicationUser[] {
    return this.applicationUsers;
  }

  get getAccountInfo() {
    return (accountid: string) => {
      return this.applicationUsers.find((user) => user.id === accountid);
    };
  }

  get getSelectedApplicationUserProfile() {
    return this.selectedApplicationUserProfile;
  }

  get getUserAvator() {
    const user = store.getters.currentUser;
    if (user.role === "SuperAdmin") {
      return "Snappy-Avator.png";
    } else {
      return "Snappy-Avator.png";
    }
  }

  @Mutation
  [Mutations.SET_USERS](payload) {
    this.applicationUsers = payload;
  }

  @Mutation
  [Mutations.ADD_A_USER](payload) {
    this.applicationUsers.unshift(payload);
  }

  @Mutation
  [Mutations.UPDATE_USER_PROFILE](payload) {
    //Selected User
    this.selectedApplicationUserProfile.username = payload.username;
    this.selectedApplicationUserProfile.firstName = payload.firstName;
    this.selectedApplicationUserProfile.lastName = payload.lastName;
    this.selectedApplicationUserProfile.jobTitle = payload.jobTitle;
    this.selectedApplicationUserProfile.companyName = payload.companyName;
    this.selectedApplicationUserProfile.email = payload.email;
    this.selectedApplicationUserProfile.emailConfirmed = payload.emailConfirmed;
    this.selectedApplicationUserProfile.phoneNumber = payload.phoneNumber;
    this.selectedApplicationUserProfile.phoneNumberConfirmed =
      payload.phoneNumberConfirmed;
    this.selectedApplicationUserProfile.twoFactorEnabled =
      payload.twoFactorEnabled;
    this.selectedApplicationUserProfile.deleted = payload.deleted;
    this.selectedApplicationUserProfile.activeAccount = payload.activeAccount;
    this.selectedApplicationUserProfile.userRoleName = payload.userRoleName;
  }

  @Mutation
  [Mutations.REMOVE_USER](payload) {
    const objIndex = this.applicationUsers.findIndex(
      (obj) => obj.id === payload.id
    );
    this.applicationUsers.splice(objIndex, 1);
  }

  @Mutation
  [Mutations.SET_APPLICATION_USER_PROFILE](payload) {
    this.selectedApplicationUserProfile = payload;
  }

  @Mutation
  [Mutations.PURGE_APPLICATION_USERS]() {
    this.applicationUsers = [] as Array<ApplicationUser>;
    this.filterApplicationUsers = [] as Array<ApplicationUser>;
    this.newApplicationUser = {} as NewApplicationUser;
    this.applicationUser = {} as ApplicationUser;
  }

  @Action
  [Actions.GET_APPLICATION_USERS]() {
    ApiService.setHeader();
    ApiService.get("usermanagement/users")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_USERS, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.GET_APPLICATION_USER_PROFILE](payload) {
    ApiService.setHeader();
    ApiService.get("usermanagement/userprofile/" + payload)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_APPLICATION_USER_PROFILE, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.GET_APPLICATION_USERS_BY_ROLE](payload) {
    ApiService.setHeader();
    ApiService.get("usermanagement/userrole/" + payload)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_USERS, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.CREATE_USER](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("UserManagement/CreateUser", payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.ADD_A_USER, data);
            resolve(data);
          } else {
            this.context.commit(Mutations.SET_ERROR, data);
            reject();
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_ACCOUNTPROFILE](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.put("UserManagement/UpdateUser", payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.UPDATE_USER_PROFILE, data);
            resolve();
          } else {
            this.context.commit(Mutations.SET_ERROR, data);
            reject();
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.DEACTIVATE_REACTIVATE_USER](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.put("UserManagement/DeactivateReactivateUser", payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.UPDATE_USER_PROFILE, data);
            resolve();
          } else {
            this.context.commit(Mutations.SET_ERROR, data);
            reject();
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.RESET_PASSWORD](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.put("UserManagement/ResetPassword", payload)
        .then(({ data }) => {
          if (data.success) {
            resolve();
          } else {
            this.context.commit(Mutations.SET_ERROR, data);
            reject();
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_USER](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.delete("UserManagement/" + payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.REMOVE_USER, data);
            resolve();
          } else {
            this.context.commit(Mutations.SET_ERROR, data);
            reject();
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
          reject();
        });
    });
  }
}
