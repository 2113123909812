import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule, Mutation } from "vuex-module-decorators";
import ApiService from "@/core/services/ApiService";
import { CreateShipmentLabelsResponse } from "@/core/types/ShipmentTypes";
import { ShippingStatusChangeResponse } from "@/core/types/SP_API_ShippingTypes";

@Module
export default class ShippingModule extends VuexModule {
  createShipmentLabelsResponse = {
    mergedPdfLabelsFile: "",
    failedShipments: [],
    success: false,
    errors: [],
    numberOfPackages: 0,
    pickTicketFilename: "",
  } as CreateShipmentLabelsResponse;

  shippingStatusChangeResponse = {
    success: false,
    errors: [],
    amazonOrderIds: [],
  } as ShippingStatusChangeResponse;

  get getCreateShipmentLabelsResponse(): CreateShipmentLabelsResponse {
    return this.createShipmentLabelsResponse;
  }

  get getShippingStatusChangeResponse(): ShippingStatusChangeResponse {
    return this.shippingStatusChangeResponse;
  }

  @Mutation
  [Mutations.SET_CREATE_SHIPMENT_LABELS_RESPONSE](payload) {
    this.createShipmentLabelsResponse = payload;
  }

  @Mutation
  [Mutations.SET_SHIPPING_STATUS_CHANGE_RESPONSE](payload) {
    this.shippingStatusChangeResponse = payload;
  }

  //Actions
  @Action
  [Actions.CREATE_SHIPMENTS](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("Shipment/CreateShipments", payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_CREATE_SHIPMENT_LABELS_RESPONSE,
              data
            );
            resolve();
          } else {
            this.context.commit(
              Mutations.SET_CREATE_SHIPMENT_LABELS_RESPONSE,
              data
            );
            reject();
          }
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_CREATE_SHIPMENT_LABELS_RESPONSE,
            response
          );
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_SHIPPING_STATUS](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("SPAPIFeeds/ShippingStatus", payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.UPDATE_UNSHIPPED_ORDERS, data);
            this.context.commit(
              Mutations.SET_SHIPPING_STATUS_CHANGE_RESPONSE,
              data
            );
            resolve();
          } else {
            this.context.commit(
              Mutations.SET_SHIPPING_STATUS_CHANGE_RESPONSE,
              data.errors
            );
            reject();
          }
        })
        .catch(({ response }) => {
          console.log("error: " + response.data);
          this.context.commit(
            Mutations.SET_SHIPPING_STATUS_CHANGE_RESPONSE,
            response.data
          );
          reject();
        });
    });
  }
}
