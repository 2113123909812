import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";

const routes: Array<RouteRecordRaw> = [
  /*{
    path: "/",
    component: () => import("@/views/SiteIndex.vue"),
  },*/
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/contact-support",
        name: "contact-support",
        component: () => import("@/views/Contact-Support.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/fbm-package-labels-to-be-printed",
        name: "fbm-package-labels-to-be-printed",
        component: () =>
          import("@/views/amazon/Fbm-Package-Labels-To-Be-Printed.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/fbm-unshipped-orders",
        name: "fbm-unshipped-orders",
        component: () => import("@/views/amazon/Fbm-Unshipped-Orders.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/fbm-shipped-orders",
        name: "fbm-shipped-orders",
        component: () => import("@/views/amazon/Fbm-Shipped-Orders.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/fbm-cancelled-orders",
        name: "fbm-cancelled-orders",
        component: () => import("@/views/amazon/Fbm-Cancelled-Orders.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/shipments",
        name: "ups-shipments",
        component: () => import("@/views/ups/Shipments.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/shippingrates",
        name: "ups-shippingrates",
        component: () => import("@/views/ups/Shipping-Rates.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/verifyaddress",
        name: "ups-verifyaddress",
        component: () => import("@/views/ups/Verify-Address.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/picklist",
        name: "pick-list",
        component: () => import("@/views/shipping/pick-list.vue"),
        meta: { requiresAuth: true },
      },
      // start:: SuperAdmin Users
      {
        path: "/super-admin/applicationuserlisting",
        name: "applicationuserlisting",
        component: () =>
          import("@/views/super-admin/users/ApplicationUserListing.vue"),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          if (store.getters.currentUserRole !== "SuperAdmin")
            next({ name: "dashboard" });
          else next();
        },
      },
      // end:: SuperAdmin Users
      // start:: User Account Profile
      {
        path: "/account/:accountid",
        name: "account",
        component: () => import("@/views/account/Account.vue"),
        meta: { requiresAuth: true },
        props: true,
        children: [
          {
            path: "/overview/:accountid",
            name: "account-overview",
            component: () => import("@/views/account/Overview.vue"),
            meta: { requiresAuth: true },
            props: true,
          },
          {
            path: "/settings/:accountid",
            name: "account-settings",
            component: () => import("@/views/account/Settings.vue"),
            meta: { requiresAuth: true },
            props: true,
          },
        ],
      },
      // end:: User Account Profile
    ],
  },
  // Login and Forgot Password
  {
    path: "/",
    component: () => import("@/views/auth/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () => import("@/views/auth/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () => import("@/views/auth/SignUp.vue"),
      },
      {
        path: "/forgot-password",
        name: "forgot-password",
        component: () => import("@/views/auth/ForgotPassword.vue"),
      },
      {
        path: "/forgot-password-confirmation",
        name: "forgot-password-confirmation",
        component: () => import("@/views/auth/ForgotPasswordConfirmation.vue"),
      },
      {
        path: "/reset-your-password",
        name: "reset-your-password",
        props: (route) => ({ query: route.query }),
        component: () => import("@/views/auth/ResetYourPassword.vue"),
      },
    ],
  },
  // Public Pages
  {
    path: "/",
    component: () => import("@/layout/PublicLayout.vue"),
    meta: { requiresAuth: false },
    children: [
      {
        path: "/apply",
        meta: { requiresAuth: false },
        name: "apply",
        component: () => import("@/views/careers/apply.vue"),
      },
      {
        path: "/applySuccess",
        meta: { requiresAuth: false },
        name: "applySuccess",
        component: () => import("@/views/careers/applySuccess.vue"),
      },
    ],
  },

  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/error/Error404.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    store.dispatch(Actions.VERIFY_AUTH).then(
      //Promise returns Success
      () => {
        //console.log("success");
        if (store.getters.isUserAuthenticated) {
          next();
          return;
        }
        next("/sign-in");
      },
      //Promise returns Failure
      () => {
        next("/sign-in");
      }
    );
  } else {
    next();
  }

  /*store.dispatch(Actions.VERIFY_AUTH).then(
    (response) => {
      console.log("success");
    },
    (error) => {
      console.log("error");
    }
  );*/

  /*if (to.matched.some((record) => record.meta.requiresAuth)) {
    //if (!store.state.user) store.dispatch(Actions.VERIFY_AUTH);
    console.log("beforeEach " + store.getters.isUserAuthenticated);
    if (store.getters.isUserAuthenticated) {
      next();
      return;
    }
    next("/sign-in");
  } else {
    next();
  }*/
});

router.beforeEach(() => {
  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
