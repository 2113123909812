import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule, Mutation } from "vuex-module-decorators";
import ApiService from "@/core/services/ApiService";
import { CreateShipmentLabelsResponse } from "@/core/types/ShipmentTypes";

@Module
export default class PickListModule extends VuexModule {
  createShipmentLabelsResponse = {
    mergedPdfLabelsFile: "",
    failedShipments: [],
    success: false,
    errors: [],
    numberOfPackages: 0,
    pickTicketFilename: "",
  } as CreateShipmentLabelsResponse;

  get getCreatePickListResponse(): CreateShipmentLabelsResponse {
    return this.createShipmentLabelsResponse;
  }

  @Mutation
  [Mutations.SET_CREATE_SHIPMENT_LABELS_RESPONSE](payload) {
    this.createShipmentLabelsResponse = payload;
  }

  /*@Action
  [Actions.CREATE_PICK_LIST]() {
    ApiService.setHeader();
    ApiService.("PdfCreator/CreatePickList")
      .then(({ data }) => {
        if (data.success) {
          this.context.commit(
            Mutations.SET_CREATE_SHIPMENT_LABELS_RESPONSE,
            data
          );
        }
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_CREATE_SHIPMENT_LABELS_RESPONSE,
          response
        );
      });
  }*/

  @Action
  [Actions.CREATE_PICK_LIST](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("PdfCreator/CreatePickList", payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_CREATE_SHIPMENT_LABELS_RESPONSE,
              data
            );
            resolve();
          } else {
            this.context.commit(
              Mutations.SET_CREATE_SHIPMENT_LABELS_RESPONSE,
              data
            );
            reject();
          }
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_CREATE_SHIPMENT_LABELS_RESPONSE,
            response
          );
          reject();
        });
    });
  }
}
