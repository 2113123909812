const ID_TOKEN_KEY = "token" as string;
const REFRESH_TOKEN = "refresh_token" as string;

/**
 * @description get token form localStorage
 */
export const getToken = (): string | null => {
  return localStorage.getItem(ID_TOKEN_KEY);
};

/**
 * @description get token form localStorage
 */
export const getRefreshToken = (): string | null => {
  return localStorage.getItem(REFRESH_TOKEN);
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveToken = (token: string, refreshToken: string): void => {
  localStorage.setItem(ID_TOKEN_KEY, token);
  localStorage.setItem(REFRESH_TOKEN, refreshToken);
};

/**
 * @description remove token form localStorage
 */
export const destroyToken = (): void => {
  localStorage.removeItem(ID_TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN);
};

/**
 * @description get payload from token
 */
export const getJwtPayload = (token: string): string | null => {
  const base64Url: string = token.split(".")[1];
  const base64: string = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  return JSON.parse(jsonPayload);
};

export default {
  getToken,
  getRefreshToken,
  saveToken,
  destroyToken,
  getJwtPayload,
};
