<template>
  <router-view />
</template>

<style lang="scss">
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "~apexcharts/dist/apexcharts.css";
@import "~quill/dist/quill.snow.css";
@import "~animate.css";
@import "~sweetalert2/dist/sweetalert2.css";
@import "~nouislider/distribute/nouislider.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "~socicon/css/socicon.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "~@yaireo/tagify/src/tagify.scss";
@import "~dropzone/dist/dropzone.css";
@import "~@vueform/multiselect/themes/default.css";
@import "~prism-themes/themes/prism-shades-of-purple.css";
@import "~element-plus/lib/theme-chalk/index.css";
@import "assets/css/snappy-styles.css";

// Main demo style scss
@import "assets/sass/plugins";
@import "assets/sass/style";

// Dark mode demo style scss
//@import "assets/sass/plugins.dark";
//@import "assets/sass/style.dark";

//RTL version styles
//@import "assets/css/style.rtl.css";
</style>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
//import { useStore } from "vuex";
//import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "app",
  setup() {
    //const store = useStore();

    onMounted(() => {
      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
       */
      //store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);
      //Run every 4 hours
      //setInterval(SyncFBAInventory, 14400000);
    });

    /*function SyncFBAInventory() {
      console.log("syncFBAInventory");
      store
        .dispatch(Actions.SYNC_FBA_INVENTORY)
        .then(() => {
          //
        })
        .catch(() => {
          console.log("syncFBAInventoryError");
        });
    }*/

    return {};
  },
});
</script>
