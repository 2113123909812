
import { defineComponent, onMounted } from "vue";
//import { useStore } from "vuex";
//import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "app",
  setup() {
    //const store = useStore();

    onMounted(() => {
      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
       */
      //store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);
      //Run every 4 hours
      //setInterval(SyncFBAInventory, 14400000);
    });

    /*function SyncFBAInventory() {
      console.log("syncFBAInventory");
      store
        .dispatch(Actions.SYNC_FBA_INVENTORY)
        .then(() => {
          //
        })
        .catch(() => {
          console.log("syncFBAInventoryError");
        });
    }*/

    return {};
  },
});
