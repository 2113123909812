enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  FORGOT_PASSWORD_RESET = "forgotPasswordReset",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",

  // user actions
  GET_APPLICATION_USERS = "getApplicationUsers",
  GET_APPLICATION_USERS_BY_ROLE = "getApplicationUsersByRole",
  CREATE_USER = "createUser",
  UPDATE_ACCOUNTPROFILE = "updateAccountProfile",
  DELETE_USER = "deleteUser",
  DEACTIVATE_REACTIVATE_USER = "deactivateReactivateUser",
  RESET_PASSWORD = "resetPassword",
  GET_APPLICATION_USER_PROFILE = "getApplicationUserProfile",

  // Email actions
  EMAIL_SUPPORT = "emailSupport",

  // FBA Inventory
  SYNC_FBA_INVENTORY = "syncFbaInventory",

  // FBM Orders
  GET_FBM_ORDERS_FROM_DATE_API = "getFbmOrdersFromDateApi",
  GET_FBM_ORDERS_FROM_SQL = "getFbmOrdersFromSql",
  SYNC_FBM_ORDERS_SHIPPING_STATUS = "syncFbmOrdersShippingStatus",

  // UPS
  GET_UPS_SHIPMENT = "getUpsShipment",
  GET_UPS_SHIPPING_RATES = "getUpsShippingRates",
  GET_UPS_VERIFY_ADDRESS = "getUpsVerifyAddress",
  EXPORT_FBMORDERS_TO_UPS_WORLDSHIP = "exportFbmOrdersToUpsWorldShip",

  // Employment Application
  POST_EMPLOYMENT_APPLICATION = "postEmploymentApplication",

  // In Progress
  CREATE_PICK_LIST = "createPickList",

  // Shipments
  CREATE_SHIPMENTS = "createShipments",
  UPDATE_SHIPPING_STATUS = "updateShippingStatus",
}

enum Mutations {
  // user mutations
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_AUTHENTICATION = "setAuthentication",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_USERS = "setUsers",
  ADD_A_USER = "addAUser",
  UPDATE_USER_PROFILE = "updateUserProfile",
  REMOVE_USER = "removeUser",
  SET_APPLICATION_USER_PROFILE = "setApplicationUserProfile",
  PURGE_APPLICATION_USERS = "purgeApplicationUsers",

  // FBA Inventory
  SET_SYNC_FBA_INVENTORY = "setSyncFbaInventory",

  // FBM Orders
  SET_FBM_ORDERS_FROM_DATE_API = "setFbmOrdersFromDateApi",
  SET_FBM_ORDERS_FROM_SQL = "setFbmOrdersFromSql",
  SET_SYNC_FBM_ORDERS_SHIPPING_STATUS = "setSyncFbmOrdersShippingStatus",

  // UPS
  SET_UPS_SHIPMENT = "setUpsShipment",
  SET_UPS_SHIPPING_RATES = "setUpsShippingRates",
  SET_UPS_VERIFY_ADDRESS = "setUpsVerifyAddress",
  SET_EXPORT_FBMORDERS_TO_UPS_WORLDSHIP = "setExportFbmOrdersToUpsWorldShip",
  PURGE_EXCEL_FILE = "purgeExcelFile",

  // Shipments
  SET_CREATE_SHIPMENT_LABELS_RESPONSE = "setCreateShipmentLabelsResponse",
  UPDATE_UNSHIPPED_ORDERS = "updateUnshippedOrders",
  SET_SHIPPING_STATUS_CHANGE_RESPONSE = "setShippingStatusChangeResponse",
}

export { Actions, Mutations };
