import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule, Mutation } from "vuex-module-decorators";
import ApiService from "@/core/services/ApiService";
import { SyncFBAInventoryResponse } from "@/core/types/FbaInventoryTypes";

@Module
export default class FBAInventoryModule extends VuexModule {
  syncFBAInventoryResponse = {} as SyncFBAInventoryResponse;

  get fbaInventoryResponse() {
    return this.syncFBAInventoryResponse;
  }

  @Mutation
  [Mutations.SET_SYNC_FBA_INVENTORY](payload) {
    this.syncFBAInventoryResponse = payload;
  }

  @Action
  [Actions.SYNC_FBA_INVENTORY]() {
    ApiService.setHeader();
    ApiService.get("fbainventory/syncfbainventory")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_SYNC_FBA_INVENTORY, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
}
