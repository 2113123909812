import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  token: string;
  refreshToken: string;
  success: boolean;
  errors: string[];
  role: string;
}

export interface TokenRequestDto {
  token: string | null;
  refreshToken: string | null;
  success: boolean;
  errors: string[];
}

@Module
export default class AuthModule extends VuexModule {
  tokenRequestDto = {} as TokenRequestDto;
  errors: string[] = [];
  user = {} as User;
  isAuthenticated = !!JwtService.getToken();

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  /**
   * Get current user role
   * @returns User Role
   */
  get currentUserRole(): string {
    return this.user.role;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Verify user credentials
   * @returns boolean
   */
  get userHasCredentials(): boolean {
    if (this.user.email !== "undefined" || this.user.email !== null) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors(): Array<string> {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](data) {
    this.errors.push(data.errors);
  }

  @Mutation
  [Mutations.SET_AUTH](data) {
    if (data.success) {
      this.isAuthenticated = true;
      this.errors = [];
      JwtService.saveToken(data.token, data.refreshToken);
      let jwtPayload: any = {};
      jwtPayload = JwtService.getJwtPayload(data.token);
      this.user.id = jwtPayload.id;
      this.user.email = jwtPayload.email;
      this.user.firstName = jwtPayload.given_name;
      this.user.lastName = jwtPayload.family_name;
      this.user.role = jwtPayload.role;
    } else {
      this.isAuthenticated = false;
      this.errors = data.errors;
    }
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.SET_AUTHENTICATION](auth) {
    this.isAuthenticated = auth;
  }

  @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user.password = password;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    JwtService.destroyToken();
  }

  @Action
  [Actions.LOGIN](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("authmanagement/login", credentials)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_AUTH, data);
            resolve();
          } else {
            this.context.commit(Mutations.SET_ERROR, data);
            reject();
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
    this.context.commit(Mutations.PURGE_APPLICATION_USERS);
  }

  @Action
  [Actions.REGISTER](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("authmanagement/register", credentials)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_AUTH, data);
            resolve();
          } else {
            this.context.commit(Mutations.SET_ERROR, data);
            reject();
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("authmanagement/forgotpassword", payload)
        .then(({ data }) => {
          if (data.success) {
            resolve();
          } else {
            this.context.commit(Mutations.SET_ERROR, data.errors);
            reject();
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.FORGOT_PASSWORD_RESET](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("authmanagement/forgotpasswordreset", payload)
        .then(({ data }) => {
          if (data.success) {
            resolve();
          } else {
            this.context.commit(Mutations.SET_ERROR, data.errors);
            reject();
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.VERIFY_AUTH]() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      this.tokenRequestDto.token = JwtService.getToken();
      this.tokenRequestDto.refreshToken = JwtService.getRefreshToken();
      return new Promise<void>((resolve, reject) => {
        ApiService.post("authmanagement/refreshtoken", this.tokenRequestDto)
          .then(({ data }) => {
            this.context.commit(Mutations.SET_AUTH, data);
            resolve();
          })
          .catch(({ response }) => {
            this.context.commit(Mutations.SET_AUTHENTICATION, false);
            this.context.commit(Mutations.SET_ERROR, response.data.errors);
            reject();
          });
      });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.UPDATE_USER](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("update_user", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_USER, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }
}
