import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule, Mutation } from "vuex-module-decorators";
import ApiService from "@/core/services/ApiService";
import {
  FbmOrdersFromApiResponse,
  FbmOrder,
  SyncFBMOrderShippingStatusReponse,
} from "@/core/types/FbmOrderTypes";

@Module
export default class FBMOrderModule extends VuexModule {
  fbmOrdersFromApiResponse = {} as FbmOrdersFromApiResponse;
  fbmOrders: Array<FbmOrder> = [];
  syncFBMOrderShippingStatusReponse = {} as SyncFBMOrderShippingStatusReponse;

  //Getters
  get ordersFromApiResponse() {
    return this.fbmOrdersFromApiResponse;
  }

  get fbmOrdersFromSql() {
    return this.fbmOrders;
  }

  get fbmOrderShippingStatusResponse() {
    return this.syncFBMOrderShippingStatusReponse;
  }

  //Mutations
  @Mutation
  [Mutations.SET_FBM_ORDERS_FROM_DATE_API](payload) {
    this.fbmOrdersFromApiResponse = payload;
  }

  @Mutation
  [Mutations.SET_FBM_ORDERS_FROM_SQL](payload) {
    this.fbmOrders = payload.fbmOrders;
  }

  @Mutation
  [Mutations.UPDATE_UNSHIPPED_ORDERS](payload) {
    for (let i = 0; i < payload.amazonOrderIds.length; i++) {
      const objIndex = this.fbmOrders.findIndex(
        (obj) => obj.amazonOrderId === payload.amazonOrderIds[i]
      );
      this.fbmOrders.splice(objIndex, 1);
      console.log("flexCheckDefault" + objIndex);
    }
  }

  @Mutation
  [Mutations.SET_SYNC_FBM_ORDERS_SHIPPING_STATUS](payload) {
    this.syncFBMOrderShippingStatusReponse = payload;
  }

  //Actions
  @Action
  [Actions.GET_FBM_ORDERS_FROM_DATE_API](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("SellerOrderManagement/GetFbmOrderFromDate", payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_FBM_ORDERS_FROM_DATE_API, data);
            resolve();
          } else {
            this.context.commit(Mutations.SET_ERROR, data.errors);
            reject();
          }
        })
        .catch(({ response }) => {
          console.log("error: " + response.data);
          this.context.commit(Mutations.SET_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_FBM_ORDERS_FROM_SQL](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("SellerOrderManagement/Orders", payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_FBM_ORDERS_FROM_SQL, data);
            resolve();
          } else {
            this.context.commit(Mutations.SET_ERROR, data.errors);
            reject();
          }
        })
        .catch(({ response }) => {
          console.log("error: " + response.data);
          this.context.commit(Mutations.SET_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.SYNC_FBM_ORDERS_SHIPPING_STATUS]() {
    ApiService.setHeader();
    ApiService.get("SellerOrderManagement/SyncFBMOrderShippingStatus")
      .then(({ data }) => {
        this.context.commit(
          Mutations.SET_SYNC_FBM_ORDERS_SHIPPING_STATUS,
          data
        );
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
}
